import styled from 'styled-components';
import ReactSelect from 'react-select';

import { margins, fontSizes, fontWeight, colors, mobileThresholdPixels } from '../Home/v3/styledComponents';

export const customStyles = {
  control: base => ({
    ...base,
    borderColor: colors.blueGrey,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '300',
    borderRadius: '3px',
    paddingLeft: '7px',
    boxShadow: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${colors.blueGrey}`,
    },
  }),
  singleValue: base => ({
    ...base,
    color: colors.navy,
    fontWeight: '500',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: colors.blueGrey,
    '&:hover': {
      color: colors.navy,
    },
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'none',
  }),
};

export const setTheme = theme => (
  {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.navy,
      primary25: colors.lightGrey,
      primary50: colors.lightGrey,
      neutral50: colors.darkBlueGrey,
    },
  }
);

export const AnswerCard = styled.button`
  margin: 0px 15px 10px 15px;
  height: 42px;
  width: 243px;
  background-color: ${({ isActive }) => isActive ? colors.navy : colors.white};
  color: ${({ isActive }) => isActive ? colors.white : colors.navy};
  border-radius: 3px;
  ${({ isActive }) => isActive
    ? `box-shadow: 0px 1px 6px ${colors.blueGrey}; border-width: 0px;`
    : `border: 0.75px solid ${colors.blueGrey};`}
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
  transition: all 0.5s ease;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 2.5px 0px;
  }
`;

export const Block = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 98px 85px;
  width: 1120px;
  margin: 160px auto 80px;
  align-items: center;
  background: ${colors.whiteTransparent};
  font-family: Roboto;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 0px;
    width: 95%;
    margin: 0px 0px 120px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  height: auto;
  margin-top: ${({ smallMarginTop }) => smallMarginTop ? '10' : '60'}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 294px;
    margin-top: ${({ smallMarginTop }) => smallMarginTop ? '4' : '30'}px;
    ${({ mobileCenter }) => mobileCenter && 'justify-content: center;'}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  flex-wrap: wrap;
`;

export const Label = styled.p`
  font-family: Roboto;
  font-weight: ${fontWeight.medium};
  line-height: 26px;
  font-size: ${fontSizes.s};
  text-align: left;
  margin: auto 0px auto 14.25px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
  }
`;

export const PageTitle = styled.p`
  font-family: Gotham;
  font-weight: 600;
  font-size: ${fontSizes.x2l};
  text-align: center;
  margin: 0px 0px 67px;
  max-width: 1035px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
    line-height: 30px;
    margin: 0px 13px 40px;
    width: unset;
  }
`;

export const Question = styled.p`
  display: flex;
  font-weight: ${fontWeight.medium};
  line-height: 26px;
  font-size: ${fontSizes.s};
  margin-top: 0px;
  margin-left: 18px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    padding: 0px 3px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: auto;
    align-items: center;
    ${({ marginTopMobile }) => marginTopMobile && `margin-top: ${marginTopMobile}px;`}
    ${({ mobilePadding }) => mobilePadding && 'padding: 0px 3px;'}
  }
`;

export const Select = styled(ReactSelect)`
  width: 79px;
  margin-left: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0px;
  }
`;

export const SmallContent = styled(Content)`
  margin : 0px 50px;
  width: 750px;
  align-items: flex-start;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px 0px 0px;
    width: 294px;
    align-items: center;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.p`
  font-family: Gotham;
  font-weight: ${fontWeight.medium};
  font-size: 24px;
  text-align: center;
  margin: 0px 0px 12px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 294px;
    justify-content: center;
    font-size: 18px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

export const Wrapper = styled.section`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 98%;
    margin: -${margins.m} 1% 1% 1%;
    padding: 0;
  }
`;

export { margins, fontSizes, fontWeight, colors, mobileThresholdPixels };
