import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import OrderContainer from '../../common/OrderContainer';
import { Wrapper, Block, PageTitle, mobileThresholdPixels } from '../../../components/order/styledComponents';
import { Button } from '../../../components/Home/v2/StyledComponents';
import { StepContainer as StepContainerV3, ComponentsContainer, colors as colorsV3 }
  from '../../../components/Home/v3/styledComponents';
import routesMap from '../../../Routes';
import withOrderContext from '../../../withOrderContext';
import ArrowButton from '../../../components/order/LogoButton';
import OrderSummaryContainer from '../../../components/order/OrderSummaryContainer';

// const StepContainer1 = styled.div`
//   display: block;
//   background-color: ${colors.lightGrey};
// `;

const StyledBlock = styled(Block)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 120px;
  }
`;


const StepContainer2 = styled(StepContainerV3)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: auto;
  }
`;

const Content = styled.div`
  display: block;
  margin: auto;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${props => !props.isAvailable && `
    border-color: ${colorsV3.lightGrey3};
    color: ${colorsV3.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${props => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

function addPiece() {
  navigate(routesMap.Step1.url);
}

const Step1Summary = ({ orderContext: { clothes, slots } }) => (
  <OrderContainer>
    <Wrapper>
      <StyledBlock>
        <PageTitle>
          Dites-nous ce dont votre client a besoin en 2 min chrono
        </PageTitle>
        <Content>
          <OrderSummaryContainer
            clothes={clothes}
            selectedDates={slots}
            canEdit
          />

          <StepContainer2>
            <ComponentsContainer>
              <StyledButton onClick={addPiece} transparent isAvailable>
                + Ajouter une pièce</StyledButton>
              <StyledLink onClick={() => navigate(routesMap.Step2.url)}>
                <ArrowButton>OK, on passe au RDV !</ArrowButton>
              </StyledLink>
            </ComponentsContainer>
          </StepContainer2>
        </Content>
      </StyledBlock>
    </Wrapper>
  </OrderContainer>
);


Step1Summary.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    slots: PropTypes.shape({}),
  }).isRequired,
};

export default withOrderContext(Step1Summary);
